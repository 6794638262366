.date {
	font-family: 'Abril Fatface', cursive;
	width: 60px;
	height: 70px;
	border-radius: 4px;
	display: inline-block;
	background-color: $purple;


	 @media screen and (max-width: 1024px) {
	 	margin-bottom: 24px;
	}

}

.agenda-item {
		 @media screen and (max-width: 1024px) {
	 	border-bottom: 1px solid $bronze;
	 	padding-bottom: 24px;
	}
}