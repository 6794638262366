h1,h2,h3,h4,h5,h6 {
	font-family: 'Abril Fatface', cursive;
}

	 @media screen and (max-width: 480px) {
		h3 {
			font-size: 1.2em;
		}	

		h5 {
			font-size: 0.8em;
		}
	}

.title--bordered {
	&:after {
		border-bottom: 5px solid $bronze;
	    clear: both;
	    content: "";
	    margin: 16px auto 16px auto;
	    display: block;
	    width: 80px;
	}
}