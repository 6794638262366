nav {
	background-color: #000;
	font-family: 'Abril Fatface', cursive;

 @media screen and (max-width: 768px) {
 	max-height: 110px;
 	overflow: hidden;
 	transition: all 180ms ease-in-out;
 	padding-bottom: 24px !important;

 	&.mobile-nav--visible {
 		max-height: 1000px;
 	}
  }

}

.nav-link {
	color: #fff !important;
	border-bottom: 3px solid transparent;

	&:hover {
		border-bottom: 3px solid $purple;
	}
}

.active {
	.nav-link {
		border-bottom: 3px solid $purple;
	}
}

