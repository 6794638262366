.hero {
	margin-top: 100px;
	background-size: cover;
	background-repeat: no-repeat;
	height: calc(100vh - 100px);
}

.hero__overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: black;
	opacity: 0.46;
	margin-top: 100px;
}