.album__link {
	@include transition(200ms);

	&:hover {
		text-decoration: none;

		img {
			opacity: 0.8;
		}
	}
}

.thumb {
	position: relative;
	display: block;

	.add-to-cart {
		outline: none;
		transition: all 0.15s ease-in-out;
		background-color: $purple;
		position: absolute;
		right: 0;
		bottom: 0;
		border-radius: 0;

			.delete {
				display: none;
			}

		&.active {
			background-color: $gold;
			.add {
				display: none;

			}
			.delete {
				display: inline-block;
			}
		}
	}

}



.video__link {
	position: relative;
	@include transition(200ms);
	i {
		opacity: 0.6;
		font-size: 2em;
		width: 40px;
		margin: auto;
		color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&:hover {
		i {
			opacity: 1;
		}
	}
}