/* Common button styles */
.button {
	font-family: 'Abril Fatface', cursive;
	cursor: pointer;
	min-width: 150px;
	max-width: 250px;
	display: block;
	margin: auto;
	padding: 1em 2em;
	border: none;
	background: none;
	color: inherit;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	border: 3px solid #fff;
}
.button:focus {
	outline: none;
}

.button:hover {
	text-decoration: none;
}

/* main */
.button.button--main {
	background: #fff;
	color: #37474f;
	overflow: hidden;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}
.button--main.button--inverted {
	background: none;
	color: #fff;
}
.button--main::before,
.button--main::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	bottom: 100%;
	left: 0;
	z-index: -1;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--main::before {
	background: $gold;
}
.button--main::after {
	background: $bronze;
}
.button--main:hover {
	color: #fff;
}
.button--main:hover::before,
.button--main:hover::after {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}
.button--main:hover::after {
	-webkit-transition-delay: 0.175s;
	transition-delay: 0.175s;
}


/* Secondary */
.button.button--secondary {
	background: lighten($purple, 2%);
	border: 3px solid lighten($purple, 2%);
	color: #fff;
	overflow: hidden;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}
.button--secondary.button--inverted {
	background: none;
	color: #fff;
}
.button--secondary::before,
.button--secondary::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	bottom: 100%;
	left: 0;
	z-index: -1;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--secondary::before {
	background: $purple;
}
.button--secondary::after {
	background: darken($purple, 2%);
}
.button--secondary:hover {
	border: 3px solid darken($purple, 2%);
	color: #fff;
}
.button--secondary:hover::before,
.button--secondary:hover::after {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}
.button--secondary:hover::after {
	-webkit-transition-delay: 0.175s;
	transition-delay: 0.175s;
}


