.cart__btn {

	position: relative;
	background-color: $purple;
	padding: 1rem;
	min-height: auto !important;
	min-height: auto !important;

	&:hover {
		background-color: rgba($purple, 0.6);
	}


	.cart__badge {
		display: none;
		font-family: 'Barlow', sans-serif;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		background-color: red;
		position: absolute;
		width: 18px;
		height: 18px;
		border-radius: 16px;
		top: 12px;
		right: 10px;
	}
}

.honeypot {
	display: none;
	position: fixed;
	z-index: -999;
}